<template>
  <GroupForm v-bind="props" :form="form" />
</template>

<script>
import GroupForm from "@/components/forms/GroupForm.vue";
import { mapGetters } from "vuex";
export default {
  components: { GroupForm },
  computed: {
    ...mapGetters(["getUser", "getSingleCompetitionGroup"]),
    form: {
      get() {
        return this.getSingleCompetitionGroup;
      },
    },
  },
  created() {
    this.$store.dispatch("initSingleCompetitionGroup", {
      groupId: this.$route.params.id,
    });
  },
  data() {
    return {
      props: {
        title: "Yarışma Grubu Güncelle",
        submit: (form) => {
          this.$store.dispatch("editCompetitionGroup", form).then(() =>
            this.$router.push({
              name: "FederationGroups",
              params: { id: this.$route.params.competitionId },
            })
          );
        },
      },
    };
  },
};
</script>