<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-4 text-white"></label>
          <b-button
            variant="dark"
            class="mr-2"
            :to="{
              name: 'FederationGroups',
              params: { id: $route.params.competitionId },
            }"
            >Geri</b-button
          >
          <b-button variant="primary" class="ml-4" type="submit">Gönder</b-button>
        </template>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <b-row>
          <b-col>
            <InputTile label="Kategori" :custom="true">
              <b-form-select
                v-model="form.category"
                :options="options.category"
                value-field="id"
                text-field="title"
              />
            </InputTile>
            <InputTile label="Tekne Sınıfı" :custom="true">
              <b-form-select
                v-model="form.boatType"
                :options="options.boatType"
                text-field="title"
                value-field="id"
              />
            </InputTile>
            <InputTile label="Mesafe" :custom="true">
              <b-form-select
                v-model="form.distance"
                :options="options.distance"
                text-field="title"
                value-field="id"
              />
            </InputTile>
            <InputTile label="Derece" v-model="form.degree" type="text" />
            <InputTile label="Ödül" v-model="form.prize" type="text" />
            <InputTile
              label="Açıklama"
              v-model="form.description"
              type="text"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import InputTile from "./common/InputTile.vue";
import { mapGetters } from "vuex";
import selectionConstants from "../../store/constants/selections";

export default {
  components: { InputTile },
  name: "GroupForm",
  computed: {
    ...mapGetters([
      "getMatchFilter",
      "getManagementItems",
      "getMatchFilterOptions",
      "getSingleCompetition",
    ]),
    options: {
      get() {
        const manItems = this.getManagementItems;
        return {
          boatType: manItems.filter((e) => {
            return e.category == selectionConstants.BOAT_CLASS; //BOAT FILTER
          }),
          category: manItems
            .filter((e) => {
              return e.category == selectionConstants.CATEGORY; //CATEGORY FILTER
            })
            .filter((el) => {
              return this.getSingleCompetition.category
                .split(";")
                .includes(String(el.id));
            }),
          distance: manItems.filter((e) => {
            return e.category == selectionConstants.DISTANCE; //DISTANCE FILTER
          }),
        };
      },
    },
  },
  data() {
    return {};
  },
  props: ["title", "submit", "form"],
  created() {
    this.$store.dispatch("initManagement");
    this.$store.dispatch(
      "initSingleCompetition",
      this.$route.params.competitionId
    );
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.submit(this.form);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>